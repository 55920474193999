<template>
  <div id="stores">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "stores",
  data() {
    return {};
  },
  created() {
    document.title = "社区订单";
    let path = this.$route.query.path;
    let query = "";
    if (this.$route.query.query != "undefined") {
      query = this.$route.query.query;
    }
    if (!this.$utils.rule.empty(query)) {
      query = JSON.parse(decodeURIComponent(query));
    }
    if (path) {
      this.$router.replace({ path: "stores/" + path, query: query });
    }
  },
};
</script>
<style scoped>
#stores {
  width: 100%;
  height: 100%;
}
</style>
